import { useEffect, useState } from "react";
import { Plus, Trash } from "phosphor-react";
import { Button } from "../../../components/button/Button";
import { Select } from "../../../components/select/Select";
import { Body } from "../../../components/typography/body/Body";
import { Option } from "../../../lib/interfaces/input";
import { hourOptions } from "../editWeeklyHours/EditWeeklyHours";
import { HourType } from "../addDateOverrides/AddDateOverrides";
import styles from "./style.module.css";
import { format } from "date-fns";

export type ButtonIcon = "plus" | "trash";

export interface SelectDateOverrideHoursProps {
  index: number;
  startHour: Option;
  endHour: Option;
  onChange: (type: HourType, index: number, hour: Option) => void;
  onAdd?: () => void;
  onDelete?: (index: number) => void;
  buttonIcon: ButtonIcon;
  saveDisabled?: (index: number) => void;
  saveNotDisabled?: (index: number) => void;
}

export const SelectDateOverrideHours = ({
  index,
  startHour,
  endHour,
  onChange = () => {
    /* empty */
  },
  onAdd = () => {
    /* empty */
  },
  onDelete = () => {
    /* empty */
  },
  buttonIcon,
  saveDisabled = () => {
    /* empty */
  },
  saveNotDisabled = () => {
    /* empty */
  },
}: SelectDateOverrideHoursProps) => {
  const [startHourState, setStartHourState] = useState<Option>(startHour);
  const [endHourState, setEndHourState] = useState<Option>(endHour);
  const [invalidStartTime, setInvalidStartTime] = useState<boolean>(false);
  const [invalidEndTime, setInvalidEndTime] = useState<boolean>(false);

  const getTimeString = (hourString: string) => {
    const today = format(new Date(), "yyyy/MM/dd ");
    const time = hourString.substring(0, hourString.length - 2);
    const AMPM = hourString.substring(hourString.length - 2, hourString.length);
    return today + time + " " + AMPM;
  };

  const checkValidTime = () => {
    const startStr = getTimeString(startHourState.value);
    const endStr = getTimeString(endHourState.value);
    const start = new Date(Date.parse(startStr));
    const end = new Date(Date.parse(endStr));
    return start < end;
  };

  useEffect(() => {
    if (!checkValidTime()) {
      setInvalidStartTime(true);
      saveDisabled(index);
    } else {
      // if(invalidStartTime) { saveNotDisabled(index) }
      setInvalidStartTime(false);
      setInvalidEndTime(false);
      saveNotDisabled(index);
    }
  }, [startHourState]);

  useEffect(() => {
    if (!checkValidTime()) {
      setInvalidEndTime(true);
      saveDisabled(index);
    } else {
      // if(invalidEndTime) { saveNotDisabled(index) }
      setInvalidStartTime(false);
      setInvalidEndTime(false);
      saveNotDisabled(index);
    }
  }, [endHourState]);

  const selectStartHour = (startHour: Option) => {
    onChange("from", index, startHour);
    setStartHourState(startHour);
  };

  const selectEndHour = (endHour: Option) => {
    onChange("to", index, endHour);
    setEndHourState(endHour);
  };

  const handleDelete = () => {
    saveNotDisabled(index);
    onDelete(index);
  };

  return (
    <>
      <div className={styles.selectContainer}>
        <Select
          onChange={selectStartHour}
          value={startHourState}
          options={hourOptions}
          error={invalidStartTime}
          width={125}
          className={styles.startHour}
        ></Select>
        <Body size="md" color="primary">
          {"-"}
        </Body>
        <Select
          onChange={selectEndHour}
          value={endHourState}
          options={hourOptions}
          error={invalidEndTime}
          width={125}
          className={styles.endHour}
        ></Select>
        <Button
          onClick={() => {
            buttonIcon === "plus" ? onAdd() : handleDelete();
          }}
          type="tertiary"
          size="small"
          Icon={buttonIcon === "plus" ? Plus : Trash}
        />
      </div>
      {(invalidStartTime || invalidEndTime) && (
        <Body color="danger" className={styles.errorMsg}>
          Choose an end time later than the start time.
        </Body>
      )}
    </>
  );
};
