import { Check, X } from "phosphor-react";
import { ModalContent, ModalOverlay, IconWrapper, Message } from "./Styled";
interface SuccessModalProps {
  message: string;
  isVisible: boolean;
  isError?: boolean;
}

const SuccessModal = ({ message, isVisible, isError }: SuccessModalProps) => {
  if (!isVisible) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <IconWrapper $isError={isError}>
          {isError ? (
            <X size={32} color="var(--color-white)" weight="bold" />
          ) : (
            <Check size={32} color="var(--color-white)" weight="bold" />
          )}
        </IconWrapper>
        <Message $isError={isError}>{message}</Message>
      </ModalContent>
    </ModalOverlay>
  );
};

export default SuccessModal;
