import { useEffect, useState } from "react";
import { Select } from "../../../components/select/Select";
import { Body } from "../../../components/typography/body/Body";
import { Option } from "../../../lib/interfaces/input";
import { hourOptions } from "../editWeeklyHours/EditWeeklyHours";
import styles from "./style.module.css";
import { format } from "date-fns";

export interface SelectWeeklyHoursProps {
  day: string;
  index: number;
  startHour: Option;
  endHour: Option;
  onChange?: (hours: Option, day: string, index: number, type: string) => void;
  saveDisabled?: (dayIndexString: string) => void;
  saveNotDisabled?: (dayIndexString: string) => void;
}

export const SelectWeeklyHours = ({
  day,
  index,
  startHour,
  endHour,
  onChange = () => {
    /* empty */
  },
  saveDisabled = () => {
    /* empty */
  },
  saveNotDisabled = () => {
    /* empty */
  },
}: SelectWeeklyHoursProps) => {
  const [startHourState, setStartHourState] = useState<Option>(startHour);
  const [endHourState, setEndHourState] = useState<Option>(endHour);
  const [invalidStartTime, setInvalidStartTime] = useState<boolean>(false);
  const [invalidEndTime, setInvalidEndTime] = useState<boolean>(false);

  const getTimeString = (hourString: string) => {
    const today = format(new Date(), "yyyy/MM/dd ");
    const time = hourString.substring(0, hourString.length - 2);
    const AMPM = hourString.substring(hourString.length - 2, hourString.length);
    return today + time + " " + AMPM;
  };

  const checkValidTime = () => {
    const startStr = getTimeString(startHourState.value);
    const endStr = getTimeString(endHourState.value);
    const start = new Date(Date.parse(startStr));
    const end = new Date(Date.parse(endStr));
    return start < end;
  };

  useEffect(() => {
    if (!checkValidTime()) {
      setInvalidStartTime(true);
      saveDisabled(day + index);
    } else {
      // if(invalidStartTime) { saveNotDisabled(day + index) }
      setInvalidStartTime(false);
      setInvalidEndTime(false);
      saveNotDisabled(day + index);
    }
  }, [startHourState]);

  useEffect(() => {
    if (!checkValidTime()) {
      setInvalidEndTime(true);
      saveDisabled(day + index);
    } else {
      // if(invalidEndTime) { saveNotDisabled(day + index) }
      setInvalidStartTime(false);
      setInvalidEndTime(false);
      saveNotDisabled(day + index);
    }
  }, [endHourState]);

  const selectStartHour = (startHour: Option) => {
    onChange(startHour, day, index, "from");
    setStartHourState(startHour);
  };

  const selectEndHour = (endHour: Option) => {
    onChange(endHour, day, index, "to");
    setEndHourState(endHour);
  };

  return (
    <div className={styles.addWeeklyHours}>
      <div className={styles.editWeekdayAndHours}>
        <Select
          onChange={selectStartHour}
          value={startHourState}
          options={hourOptions}
          error={invalidStartTime}
          width={125}
          className={styles.startHour}
        ></Select>
        <Body className={styles.dash} size="sm">
          {"-"}
        </Body>
        <Select
          onChange={selectEndHour}
          value={endHourState}
          options={hourOptions}
          error={invalidEndTime}
          width={125}
          className={styles.endHour}
        ></Select>
      </div>
      {(invalidStartTime || invalidEndTime) && (
        <Body color="danger" className={styles.errorMsg}>
          Choose an end time later than the start time.
        </Body>
      )}
    </div>
  );
};
