import styled from "styled-components";
import { Button } from "../../../../components/button/Button";

export const AddAttendeeButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const StyledAddAttendeeButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid var(--color-primary-700);
  background: var(--color-primary-500);
  display: flex;
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  color: var(--color-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &:hover {
    background: var(--color-primary-700);
    border: 1px solid var(--color-primary-700);
    color: var(--color-white);
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:focus-visible {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
`;

export const StyledTitle = styled.div`
  color: var(--color-gray-900);
  font-family: "HelveticaNowText";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

export const Field = styled.div`
  margin-bottom: 24px;
`;

export const Label = styled.label`
  display: block;
  font-size: 16px;
  color: var(--color-gray-900);
  margin-bottom: 5px;
  margin-top: 24px;
  font-family: "HelveticaNowText";
  font-weight: 500;
  line-height: 150%;
`;

export const SearchContainer = styled.div`
  display: flex;
  height: 48px;
  border: 1px solid var(--color-gray-300);
  border-radius: 8px;
  margin-top: 8px;
`;

export const DropdownSection = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 12px 0px 16px;
  gap: 8px;
  border-right: 1px solid var(--color-gray-300);
`;

export const MembershipText = styled.span`
  color: var(--v2-color-text-dark);
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  font-style: normal;
`;

export const SearchSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const SelectedPatient = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0px 8px;
  height: 24px;
  border-radius: 12px;
  background: var(--color-primary-500);
  color: var(--color-white);
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  font-style: normal;
  padding: 0px 12px;
  font-family: "HelveticaNowText";
`;

export const SearchIconWrapper = styled.div`
  margin-right: 16px;
`;

export const RadioGroup = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 8px;
`;

export const RadioOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RadioLabel = styled.label`
  font-family: "HelveticaNowDisplay";
  font-size: 16px;
  font-weight: 400;
  color: var(--color-gray-900);
  cursor: pointer;
  transition: all 0.2s ease;
`;

export const RadioInput = styled.input`
  width: 24px;
  height: 24px;
  margin: 0;
  cursor: pointer;
  accent-color: var(--color-primary-500);
  padding: 4px;
  border-radius: 24px;

  &:checked + ${RadioLabel} {
    color: var(--color-primary-500);
    font-weight: 700;
  }
`;

export const PhoneInputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border: 1px solid var(--color-gray-300);
  border-radius: 8px;
  margin-top: 8px;
  padding: 0 16px;
  max-width: 288px;
`;

export const PhoneIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export const ClearIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 12px;
`;

export const StyledPhoneInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-family: "ProximaNova";
  font-size: 16px;
  font-weight: 400;
  color: var(--color-gray-900);

  &::placeholder {
    color: var(--v2-gray-placeholder);
  }

  &:focus {
    outline: none;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

export const CopyLinkButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: var(--v2-primary-blue);
  font-family: "ProximaNova";
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  padding: 8px;
  min-width: 200px;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const EmailInputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border: 1px solid var(--color-gray-300);
  border-radius: 8px;
  margin-top: 8px;
  padding: 0 16px;
  max-width: 288px;
`;

export const EmailIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export const StyledEmailInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-family: "ProximaNova";
  font-size: 16px;
  font-weight: 400;
  color: var(--color-gray-900);

  &::placeholder {
    color: var(--v2-gray-placeholder);
  }

  &:focus {
    outline: none;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--v2-gray-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in;
`;

export const ModalContent = styled.div`
  background: var(--color-white);
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  min-width: 320px;
  animation: slideIn 0.3s ease-out;

  @keyframes slideIn {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const IconWrapper = styled.div<{ $isError?: boolean }>`
  background: var(--color-primary-700);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.p<{ $isError?: boolean }>`
  color: ${(props) =>
    props.$isError ? "var(--color-gray-900)" : "var(--color-primary-500)"};
  font-family: "HelveticaNowText";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 0;
`;
