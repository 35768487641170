import { useState } from "react";
import { UserPlus } from "phosphor-react";
import { AddAttendeeButtonWrapper, StyledAddAttendeeButton } from "./Styled";
import { Modal } from "../../../../components/modal/Modal";
import AttendeeForm from "./AttendeeForm";

const AddAttendeeButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <AddAttendeeButtonWrapper>
      <StyledAddAttendeeButton
        onClick={handleOpenModal}
        label="Add Attendee"
        type="secondary"
        Icon={() => <UserPlus size={20} />}
        iconPosition="left"
      />

      <Modal
        visible={isModalOpen}
        onCloseModal={handleCloseModal}
        title="Add Attendee"
      >
        <AttendeeForm onClose={handleCloseModal} />
      </Modal>
    </AddAttendeeButtonWrapper>
  );
};

export default AddAttendeeButton;
