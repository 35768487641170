import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { X } from "phosphor-react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";

import { StyledP } from "./Styled";
import { BaseControl } from "../BaseControl";

const EndMeetingControl: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = (): void => setShowModal(!showModal);
  const history = useHistory();
  const meetingManager = useMeetingManager();

  const leaveMeeting = async (): Promise<void> => {
    if (meetingManager.meetingSession) await meetingManager.leave();
    history.push("/scheduling");
  };

  return (
    <>
      <BaseControl
        icon={<X size={20} />}
        onClick={toggleModal}
        label="End"
        backgroundColor="#d92d20"
      />
      {showModal && (
        <Modal size="md" onClose={toggleModal} rootId="modal-root">
          <ModalHeader title="End Visit" />
          <ModalBody>
            <StyledP>
              Click leave visit to confirm or click cancel to return to the
              visit
            </StyledP>
          </ModalBody>
          <ModalButtonGroup
            primaryButtons={[
              <ModalButton
                key="leave-meeting"
                onClick={leaveMeeting}
                variant="secondary"
                label="Leave Meeting"
                closesModal
              />,
              <ModalButton
                key="cancel-meeting-ending"
                variant="secondary"
                label="Cancel"
                closesModal
              />,
            ]}
          />
        </Modal>
      )}
    </>
  );
};

export default EndMeetingControl;
