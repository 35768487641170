import { Body } from "../../../components/typography/body/Body";
import { ProviderInfo, UserInfo } from "../../../lib/interfaces/user";
import { AvatarLabelGroup } from "../../../components/avatarLabelGroup/AvatarLabelGroup";
import { FeaturedIcon } from "../../../components/featuredIcon/FeaturedIcon";
import { Modal } from "../../../components/modal/Modal";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { Trash } from "phosphor-react";
import { useCareTeam } from "../../../lib/hooks/useCareTeam";
import { CareTeamPayload } from "../../../lib/apis/careTeam";
import styles from "./style.module.css";
import { useContext, useEffect, useState } from "react";
import { BaseContext } from "../../../lib/context/context";
import { ProviderType } from "../../../lib/apis/types/provider.types";

export interface CareTeamDetailProps {
  careTeam: ProviderInfo[];
  patientId: string;
  updateDisplayedCareTeam: (updatedCareTeam: ProviderInfo[]) => void;
}

export const CareTeamDetail = ({
  careTeam,
  patientId,
  updateDisplayedCareTeam,
}: CareTeamDetailProps) => {
  const { userInfo } = useContext(BaseContext);
  const { updateCareTeam } = useCareTeam();

  const [updatedCareTeam, setUpdatedCareTeam] = useState<UserInfo[]>([]);
  const [removeMember, setRemoveMember] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState("");

  useEffect(() => {
    setUpdatedCareTeam(careTeam);
  }, [careTeam]);

  const onRemove = (memberId: string) => {
    setMemberToRemove(memberId);
    setRemoveMember(true);
  };

  const onCancel = () => {
    setRemoveMember(false);
    setMemberToRemove("");
  };

  const onConfirmRemove = (teamMemberId: string) => {
    const updatedCareTeam = careTeam.filter(
      (member) => member.id !== teamMemberId
    );
    const providerIds = updatedCareTeam.map((provider) => {
      return provider.id;
    });
    const postBody: CareTeamPayload = {
      userId: patientId,
      providerIds: providerIds,
    };
    //post the updated care team to the db
    updateCareTeam(postBody);
    //update the care team displayed in the patient header
    updateDisplayedCareTeam(updatedCareTeam);
    //update the care team displayed in the care team detail
    setUpdatedCareTeam(updatedCareTeam);
    setRemoveMember(false);
  };

  return (
    <div>
      {updatedCareTeam.map((provider) => {
        return (
          <div key={provider.id} className={styles.careTeamMemberContainer}>
            <AvatarLabelGroup users={[provider]} />
            {(userInfo?.providerType === ProviderType.CareCoordinator ||
              userInfo?.providerType === ProviderType.SiteAdmin) && (
              <>
                <div
                  onClick={() => {
                    onRemove(provider.id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FeaturedIcon type="danger" Icon={Trash} size="sm" />
                </div>
                <Body className={styles.body} size="sm" color="secondary">
                  Remove from Care Team
                </Body>
              </>
            )}
          </div>
        );
      })}
      {removeMember && (
        <Modal
          onCloseModal={() => {
            /* empty */
          }}
          title="Confirm Remove"
          dismissable={false}
        >
          <div style={{ overflow: "auto" }}>
            <Body>
              Are you sure you want to remove this care team member? They cannot
              be added back until the patient schedules with them again.
            </Body>
            <div style={{ height: 32 }} />
            <ButtonGroup align="end">
              <Button onClick={onCancel} type="secondary-gray" label="Cancel" />
              <Button
                onClick={() => {
                  onConfirmRemove(memberToRemove);
                }}
                label="Remove"
              />
            </ButtonGroup>
          </div>
        </Modal>
      )}
    </div>
  );
};
