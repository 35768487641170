import { useEffect, useState } from "react";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import {
  MeetingSessionConfiguration,
  ConsoleLogger,
  DefaultEventController,
  LogLevel,
} from "amazon-chime-sdk-js";
import { useRouteMatch, useHistory } from "react-router-dom";

import { Spinner } from "../../../../components/spinner/Spinner";

import {
  joinMeeting,
  createGetAttendeeCallback,
} from "../../../../lib/apis/videoChat";
import { useVideoChat } from "../../context/VideoChatContext";

import { StyledContainer } from "./Styled";
import { useAuthContext } from "../../../../contexts/AuthContext";
const Home = () => {
  const meetingManager = useMeetingManager();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { meetingId, attendeeId } = useVideoChat();
  const [err, setErr] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useAuthContext();

  useEffect(() => {
    let errorTimeout: NodeJS.Timeout;
    if (meetingId && attendeeId && token) {
      setLoading(true);
      const join = async () => {
        const { JoinInfo } = await joinMeeting({
          visitId: meetingId as string,
          userId: attendeeId as string,
        });
        if (JoinInfo) {
          const { Meeting, Attendee } = JoinInfo;
          meetingManager.getAttendee = createGetAttendeeCallback(meetingId as string);
          const configuration = new MeetingSessionConfiguration(
            Meeting,
            Attendee
          );

          await meetingManager.join(configuration);

          history.push(`${url}/devices`, {
            visitId: Meeting.ExternalMeetingId,
          });
        }
        setLoading(false);
      };

      join();
    } else {
      errorTimeout = setTimeout(() => setErr(true), 1500);
      setLoading(false);
    }

    return () => clearTimeout(errorTimeout);
  }, [meetingId,token]);

  if (loading) {
    return (
      <StyledContainer>
        <Spinner size={96} />
      </StyledContainer>
    );
  }
  return (
    <StyledContainer>
      {err ? <h1>Something went wrong, please try again</h1> : ""}
    </StyledContainer>
  );
};

export default Home;
