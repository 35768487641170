import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from "./style.module.css";
import checkedOption from "../../assets/icons/checkedOption.svg";
import dropdownArrow from "../../assets/icons/dropdownDownArrow.svg";
import { Option } from "../../lib/interfaces/input";
import { MembershipTierLabel } from "../../lib/interfaces/user";

export interface MembershipTierDropdownProps {
  membershipTierOptions: Option[];
  onChange?: (value: any) => void;
  selectMembershipTier: Option;
}

export const MembershipTierDropdown: React.FC<MembershipTierDropdownProps> = ({
  membershipTierOptions,
  onChange = () => {
    /* empty */
  },
  selectMembershipTier,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<Option>(selectMembershipTier);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option.value);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownArrowStyle = {
    backgroundImage: `url(${dropdownArrow})`,
  };

  return (
    <div className={clsx(styles.dropdown)} ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={clsx(styles.dropdownToggle)}
        style={dropdownArrowStyle}
      >
        {selectedOption ? selectedOption.name : MembershipTierLabel.TIER_ALL}
      </button>
      {isOpen && (
        <ul className={clsx(styles.dropdownMenu)}>
          {membershipTierOptions?.map((item, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(item)}
              className={clsx({ [styles.selected]: selectedOption === item })}
            >
              {item.name}
              {selectedOption === item && (
                <img src={checkedOption} alt="Selected" />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
