import {
  Dispatch,
  SetStateAction,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";
import { PatientCardInfo } from "../../../lib/interfaces/user";

interface IVideoChatContext {
  meetingId: string | undefined;
  setMeetingId: Dispatch<SetStateAction<string | undefined>>;
  showRoster: boolean;
  toggleRoster: () => void;
  attendeeId: string | undefined;
  setAttendeeId: Dispatch<SetStateAction<string | undefined>>;
  patient: PatientCardInfo | undefined;
  setPatient: Dispatch<SetStateAction<PatientCardInfo | undefined>>;
}
const VideoChatContext = createContext<IVideoChatContext>({
  meetingId: undefined,
  setMeetingId: () => {
    /* empty */
  },
  showRoster: true,
  toggleRoster: () => {
    /* empty */
  },
  attendeeId: undefined,
  setAttendeeId: () => {
    /* empty */
  },
  patient: undefined,
  setPatient: () => {
    /* empty */
  },
});

interface IVideoChatContextProvider {
  children: ReactNode;
}
const VideoChatContextProvider = ({ children }: IVideoChatContextProvider) => {
  const [meetingId, setMeetingId] = useState<string>();
  const [showRoster, setShowRoster] = useState<boolean>(true);
  const [attendeeId, setAttendeeId] = useState<string>();
  const [patient, setPatient] = useState<PatientCardInfo | undefined>();
  const toggleRoster = () => setShowRoster(!showRoster);
  return (
    <VideoChatContext.Provider
      value={{
        meetingId,
        setMeetingId,
        showRoster,
        toggleRoster,
        attendeeId,
        setAttendeeId,
        patient,
        setPatient,
      }}
    >
      {children}
    </VideoChatContext.Provider>
  );
};

const useVideoChat = () => useContext(VideoChatContext);

export { useVideoChat, VideoChatContextProvider };
