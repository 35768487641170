import axios, { HttpStatusCode } from "axios";
import {
  JoinMeetingRequest,
  JoinMeetingResponse,
  GetAttendeeResponse,
  GetAttendeeNameResponse,
  GetAttendeeRequest,
  EndMeetingRequest,
  SendWebLinkRequest,
  GetWebLinkRequest,
  GetWebLinkResponse,
} from "./types/videoChat.types";

export const joinMeeting = async ({ visitId, userId }: JoinMeetingRequest) => {
  const body = {
    visitId,
    userId,
  };
  const response: JoinMeetingResponse = await axios
    .post("/video-chat/join", body, {
      headers: {
        "content-type": "application/json",
      },
    })
    .catch((err) => err);

  return response.data;
};

export const endMeeting = async ({
  externalMeetingId,
}: EndMeetingRequest): Promise<boolean> => {
  const body = {
    externalMeetingId,
  };

  const response = await axios
    .post("/video-chat/end", body, {
      headers: {
        "content-type": "application/json",
      },
    })
    .catch((err) => err);

  if (!(response.status === HttpStatusCode.NoContent)) {
    throw new Error("Server error ending meeting");
  }
  return true;
};

export async function getAttendee({
  externalMeetingId,
  externalUserId,
}: GetAttendeeRequest): Promise<GetAttendeeNameResponse> {
  const body = {
    externalMeetingId,
    externalUserId,
  };

  const response: GetAttendeeResponse = await axios
    .post("/video-chat/attendee", body, {
      headers: {
        "content-type": "application/json",
      },
    })
    .catch((err) => err);

  if (!(response.status === HttpStatusCode.Ok)) {
    throw new Error("Invalid server response");
  }

  const { data } = response;

  return { name: data.Name };
}

export const createGetAttendeeCallback =
  (externalMeetingId: string) =>
  (
    _chimeAttendeeId: string,
    externalUserId?: string
  ): Promise<GetAttendeeNameResponse> =>
    getAttendee({ externalMeetingId, externalUserId: externalUserId! });

export const sendWebLink = async ({
  userId,
  visitId,
  type,
  recipient,
}: SendWebLinkRequest): Promise<void> => {
  const body = {
    userId,
    visitId,
    type,
    recipient,
  };

  const response = await axios
    .post("/video-chat/web-link", body, {
      headers: {
        "content-type": "application/json",
      },
    })
    .catch((err) => err);

  if (!(response.status === HttpStatusCode.Ok)) {
    throw new Error("Failed to send web link");
  }
  return response.data;
};

export const getWebLink = async ({
  userId,
  visitId,
}: GetWebLinkRequest): Promise<string> => {
  const response = await axios
    .get(`/video-chat/web-link`, {
      params: {
        userId,
        visitId,
      },
      headers: {
        "content-type": "application/json",
      },
    })
    .catch((err) => err);

  if (!(response.status === HttpStatusCode.Ok)) {
    throw new Error("Failed to get web link");
  }

  const { videoChatLink } = response.data as GetWebLinkResponse;
  return videoChatLink;
};
