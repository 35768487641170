import { v4 as uuidv4 } from "uuid";
import { Trash } from "phosphor-react";
import { Body } from "../../../components/typography/body/Body";
import { FeaturedIcon } from "../../../components/featuredIcon/FeaturedIcon";
import { OverridenDate } from "../../../lib/interfaces/availbilityManagement";
import styles from "./style.module.css";
import { format } from "date-fns";

export interface ListDateOverrides {
  editMode: boolean;
  dates: OverridenDate[];
  handleOnDelete: (date: string, index: number) => void;
}

export const ListDateOverrides = ({
  editMode,
  dates,
  handleOnDelete = () => {
    /* empty */
  },
}: ListDateOverrides) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString + "T00:00:00");
    return format(date, "EEE, MMM dd, yyyy");
  };

  return (
    <>
      {!editMode && (
        <>
          {dates.map((overridenDate) => (
            <div key={overridenDate.date} className={styles.overridenDates}>
              <Body
                className={styles.date}
                size="sm"
                weight="bold"
                color="primary"
              >
                {formatDate(overridenDate.date)}
              </Body>
              <div className={styles.hours}>
                <div className={styles.hoursAndDelete}>
                  {overridenDate.hours ? (
                    <Body
                      className={styles.hourText}
                      size="sm"
                      weight="regular"
                      color="primary"
                    >
                      {overridenDate.hours[0].from.name +
                        " - " +
                        overridenDate.hours[0].to.name}
                    </Body>
                  ) : (
                    <Body
                      className={styles.hourText}
                      size="sm"
                      weight="regular"
                      color="secondary"
                    >
                      {"Unavailable"}
                    </Body>
                  )}
                  <div
                    className={styles.deleteButton}
                    onClick={() => {
                      handleOnDelete(overridenDate.date, 0);
                    }}
                  >
                    <FeaturedIcon type="danger" Icon={Trash} size="sm" />
                  </div>
                </div>
                {overridenDate.hours && overridenDate.hours?.length > 1 && (
                  <div>
                    {overridenDate.hours?.slice(1).map((hour, index) => (
                      <div key={uuidv4()} className={styles.hoursAndDelete}>
                        <Body
                          className={styles.hourText}
                          size="sm"
                          weight="regular"
                          color="primary"
                        >
                          {hour.from.name + " - " + hour.to.name}
                        </Body>
                        <div
                          className={styles.deleteButton}
                          onClick={() => {
                            handleOnDelete(overridenDate.date, index + 1);
                          }}
                        >
                          <FeaturedIcon type="danger" Icon={Trash} size="sm" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};
