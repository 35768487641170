import { useState, useEffect } from "react";
import dropDownArrow from "../../../../assets/icons/dropdownDownArrow.svg";
import {
  MagnifyingGlass,
  X,
  DeviceMobileCamera,
  XCircle,
  Copy,
  Check,
  EnvelopeSimple,
} from "phosphor-react";
import { ButtonGroup, Button } from "../../../../components/button/Button";
import { useVideoChat } from "../../context/VideoChatContext";
import { sendWebLink, getWebLink } from "../../../../lib/apis/videoChat";
import { SendLinkType } from "../../../../lib/apis/types/videoChat.types";
import SuccessModal from "./SuccessModal";
import { logError } from "../../../../lib/util/logger";
import {
  Field,
  Label,
  SearchContainer,
  DropdownSection,
  MembershipText,
  SearchSection,
  SelectedPatient,
  SearchIconWrapper,
  RadioGroup,
  RadioOption,
  RadioInput,
  RadioLabel,
  PhoneInputContainer,
  PhoneIconWrapper,
  StyledPhoneInput,
  ClearIconWrapper,
  EmailInputContainer,
  EmailIconWrapper,
  StyledEmailInput,
  ActionContainer,
  CopyLinkButton,
} from "./Styled";

const AttendeeForm = ({ onClose }: { onClose: () => void }) => {
  const { patient, meetingId } = useVideoChat();
  const [originalPhone] = useState(patient?.mobile || "");
  const [originalEmail] = useState(patient?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(patient?.mobile || "");
  const [email, setEmail] = useState(patient?.email || "");
  const [isCopied, setIsCopied] = useState(false);
  const [inviteMethod, setInviteMethod] = useState<SendLinkType>(
    SendLinkType.SMS
  );
  const [meetingLink, setMeetingLink] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [phoneModified, setPhoneModified] = useState(false);
  const [emailModified, setEmailModified] = useState(false);

  useEffect(() => {
    const fetchMeetingLink = async () => {
      try {
        const response = await getWebLink({
          userId: patient?.userId || "",
          visitId: meetingId || "",
        });
        setMeetingLink(response);
      } catch (error) {
        logError(
          "Error to fetch meeting link:",
          { userId: patient?.userId, visitId: meetingId },
          error as Error
        );
      }
    };

    if (patient?.userId && meetingId) {
      fetchMeetingLink();
    }
  }, [patient?.userId, meetingId]);

  const formatPhoneNumber = (value: string) => {
    const digits = value.replace(/\D/g, "").slice(-10);

    if (digits.length <= 3) return digits;
    if (digits.length <= 6) return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    const selectionStart = input.selectionStart || 0;
    const rawInput = input.value;

    const formattedInput = formatPhoneNumber(rawInput);

    if (rawInput.replace(/\D/g, "").length <= 10) {
      setPhoneNumber(formattedInput);
      setPhoneModified(true);

      const digitCountBeforeCursor = rawInput
        .slice(0, selectionStart)
        .replace(/\D/g, "").length;
      let newPosition = digitCountBeforeCursor;
      if (digitCountBeforeCursor > 3) newPosition += 1;
      if (digitCountBeforeCursor > 6) newPosition += 1;

      setTimeout(() => {
        input.setSelectionRange(newPosition, newPosition);
      }, 0);
    }
  };

  const handleClearPhone = () => {
    setPhoneNumber("");
    setPhoneModified(false);
  };

  const handleCopyLink = () => {
    if (meetingLink) {
      navigator.clipboard.writeText(meetingLink);
      setIsCopied(true);

      // Reset the copied state after 3 seconds
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  };

  const isPhoneNumberValid = () => {
    const digitsOnly = phoneNumber?.replace(/\D/g, "").slice(-10);
    return digitsOnly?.length === 10;
  };

  const isEmailValid = (email: string) => {
    const emailRegex = /^[\w.-]+(\+[a-zA-Z\d]+)?@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

    if (email !== "" && email !== undefined) {
      return emailRegex.test(email);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailModified(true);
  };

  const handleClearEmail = () => {
    setEmail("");
    setEmailModified(false);
  };

  const handleInviteMethodChange = (newMethod: SendLinkType) => {
    setInviteMethod(newMethod);

    if (newMethod === SendLinkType.SMS && !phoneModified) {
      setPhoneNumber(originalPhone);
    } else if (newMethod === SendLinkType.EMAIL && !emailModified) {
      setEmail(originalEmail);
    }
  };

  const isFormValid = () => {
    if (inviteMethod === "sms") {
      return isPhoneNumberValid();
    } else {
      return isEmailValid(email);
    }
  };

  const handleAddAttendee = async () => {
    try {
      if (!patient?.userId || !meetingId) {
        setErrorMessage(
          "Unable to send meeting link: Missing required information."
        );
        return;
      }
      if (inviteMethod === "sms" && !phoneNumber) return;
      if (inviteMethod === "email" && !email) return;

      const recipient =
        inviteMethod === "sms" ? formatPhoneNumber(phoneNumber) : email;
      if (!recipient) {
        setErrorMessage(
          `Please enter a valid ${
            inviteMethod === "sms" ? "phone number" : "email address"
          }.`
        );
        return;
      }

      await sendWebLink({
        userId: patient?.userId,
        visitId: meetingId,
        type: inviteMethod,
        recipient: recipient,
      });

      const message =
        inviteMethod === "sms"
          ? "Meeting link sent successfully via SMS!"
          : "Meeting link sent successfully via email!";
      setSuccessMessage(message);
      setErrorMessage("");

      setTimeout(() => {
        setSuccessMessage("");
        onClose();
      }, 3000);
    } catch (error) {
      logError(
        "Error sending web link:",
        { userId: patient?.userId, visitId: meetingId, inviteMethod },
        error as Error
      );
      setErrorMessage("Failed to send meeting link. Please try again.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <>
      <SuccessModal
        message={successMessage || errorMessage}
        isVisible={!!(successMessage || errorMessage)}
        isError={!!errorMessage}
      />
      <Field>
        <Label>Patient's Name</Label>
        <SearchContainer>
          <DropdownSection>
            <MembershipText>All Members</MembershipText>
            <img src={dropDownArrow} alt="dropdown" />
          </DropdownSection>
          <SearchSection>
            <SelectedPatient>
              <span>{patient?.name}</span>
              <X size={12} />
            </SelectedPatient>
            <SearchIconWrapper>
              <MagnifyingGlass size={24} />
            </SearchIconWrapper>
          </SearchSection>
        </SearchContainer>
      </Field>
      <Field>
        <Label>Invite patient via:</Label>
        <RadioGroup>
          <RadioOption>
            <RadioInput
              type="radio"
              id="sms"
              name="inviteMethod"
              value={SendLinkType.SMS}
              checked={inviteMethod === SendLinkType.SMS}
              onChange={() => handleInviteMethodChange(SendLinkType.SMS)}
            />
            <RadioLabel htmlFor="sms">SMS</RadioLabel>
          </RadioOption>
          <RadioOption>
            <RadioInput
              type="radio"
              id="email"
              name="inviteMethod"
              value={SendLinkType.EMAIL}
              checked={inviteMethod === SendLinkType.EMAIL}
              onChange={() => handleInviteMethodChange(SendLinkType.EMAIL)}
            />
            <RadioLabel htmlFor="email">Email</RadioLabel>
          </RadioOption>
        </RadioGroup>
      </Field>
      {inviteMethod === SendLinkType.SMS ? (
        <PhoneInputContainer>
          <PhoneIconWrapper>
            <DeviceMobileCamera size={24} />
          </PhoneIconWrapper>
          <StyledPhoneInput
            type="tel"
            placeholder="(000)-000-0000"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            value={formatPhoneNumber(phoneNumber || "")}
            onChange={handlePhoneChange}
          />
          {phoneNumber && (
            <ClearIconWrapper onClick={handleClearPhone}>
              <XCircle size={20} color="var(--v2-gray-600)" weight="fill" />
            </ClearIconWrapper>
          )}
        </PhoneInputContainer>
      ) : (
        <EmailInputContainer>
          <EmailIconWrapper>
            <EnvelopeSimple size={24} />
          </EmailIconWrapper>
          <StyledEmailInput
            type="email"
            placeholder="Enter email address"
            value={email}
            onChange={handleEmailChange}
          />
          {email && (
            <ClearIconWrapper onClick={handleClearEmail}>
              <XCircle size={20} color="var(--v2-gray-600)" weight="fill" />
            </ClearIconWrapper>
          )}
        </EmailInputContainer>
      )}

      <ActionContainer>
        <CopyLinkButton onClick={handleCopyLink} disabled={!meetingLink}>
          {isCopied ? (
            <>
              <Check size={18} color="var(--v2-primary-blue)" weight="bold" />
              Meeting link copied to clipboard!
            </>
          ) : (
            <>
              <Copy size={18} color="var(--v2-primary-blue)" />
              Copy meeting link
            </>
          )}
        </CopyLinkButton>
        <ButtonGroup>
          <Button
            onClick={onClose}
            type="secondary"
            size="small"
            label="Cancel"
          />
          <Button
            onClick={handleAddAttendee}
            type="primary"
            size="small"
            label="Add Attendee"
            disabled={!isFormValid()}
          />
        </ButtonGroup>
      </ActionContainer>
    </>
  );
};

export default AttendeeForm;
