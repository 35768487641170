import axios, { AxiosResponse } from "axios";
import { CarePlan } from "../interfaces/carePlan";
import { FetchPillarTemplatesResponse } from "./types/careplan.types";

export interface CarePlanReponse {
  id?: string;
  userId?: string;
  focus?: {
    value?: string;
    modifiedOn?: string;
    modifiedBy?: string;
  };
  comments?: string;
  intention?: {
    value?: string;
    modifiedOn?: string;
    modifiedBy?: string;
  };
  loveletter?: {
    value?: string;
    modifiedOn?: string;
    modifiedBy?: string;
  };
  modifiedOn?: string;
  modifiedBy?: string;
  publishedOn?: string;
  publishedBy?: string;
  sentToEpicOn?: string;
  pillars?: {
    [pillar: string]: {
      resourceIds?: string[];
      comment?: {
        comment: string;
        modifiedOn?: string;
        modifiedBy?: string;
      };
      actions?: {
        actionId?: string;
        category?: string;
        activities?: string[];
        quantity?: number;
        units?: string;
        frequency?: number;
        per?: string;
        modifiedOn?: string;
        modifiedBy?: string;
      }[];
    };
  };
}

export interface Response extends AxiosResponse {
  data: CarePlanReponse[];
}

export interface ProgressItem {
  carePlanId: string;
  period: string;
  score: number;
  normalizedPeriodDate: {
    dayOfWeek: string;
    month: string;
    day: string;
    year: string;
  };
}

export interface Progress {
  userId: string;
  pillars: {
    progress?: {
      progress: ProgressItem[];
    };
    move?: {
      progress: ProgressItem[];
    };
    calm?: {
      progress: ProgressItem[];
    };
    nourish?: {
      progress: ProgressItem[];
    };
  };
}

export interface ProgressResponse {
  data: Progress;
}

export interface PillarDetailParams {
  userId: string;
  pillarId: string;
  patientGroupTypeId: string;
}

export const fetch = async (userId: string, controller?: AbortController) => {
  const result = await axios
    .get<Response, Response>("/careplan", {
      params: { userId },
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result;
};

export const fetchPublished = async (
  userId: string,
  controller?: AbortController
) => {
  const result = await axios
    .get<Response, Response>("/careplan?published=true", {
      params: { userId },
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });

  return result;
};

export const post = async (userId: string, carePlan: CarePlan) => {
  const result = await axios
    .post<Response, Response>("/careplan", {
      ...carePlan,
      userId,
    })
    .catch((err) => err);

  return result.data;
};

export const sendToEpic = async (carePlan: any) => {
  const result = await axios
    .post<Response, Response>("/careplan/sendToEpic", {
      ...carePlan,
    })
    .catch((err) => err);

  return result;
};

export const fetchProgressData = async (
  carePlanId: string,
  pillar: string,
  fromPeriod: string,
  toPeriod: string,
  publishDate?: string,
  controller?: AbortController
) => {
  let requestQuery = `?careplanId=${carePlanId}&fromPeriod=${fromPeriod}&toPeriod=${toPeriod}`;
  if (pillar) {
    requestQuery += `&pillar=${pillar}`;
  }
  if (publishDate) {
    requestQuery += `&carePlanPublishDate=${publishDate}`;
  }
  const result = await axios
    .get<ProgressResponse, ProgressResponse>(
      `/careplan/progress${requestQuery}`,
      {
        signal: controller ? controller.signal : undefined,
      }
    )
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });

  return result.data;
};

export const fetchPatientPillars = async (
  userId: string,
  controller?: AbortController
) => {
  const result = await axios
    .get<Response, Response>("/careplan/pillars", {
      params: { userId },
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result;
};

export const fetchPillarsPatientGroups = async (
  controller?: AbortController
) => {
  const result = await axios
    .get<Response, Response>("/careplan/patient-groups", {
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result;
};

export const fetchCarePlanListById = async (
  pillarId?: string,
  groupTypeId?: string,
  controller?: AbortController
) => {
  const result = await axios
    .get<Response, Response>("/careplan/list", {
      params: { pillarId, patientGroupTypeId: groupTypeId },
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result;
};

export const addCarePlan = async (
  userId: string,
  pillarId: string,
  patientGroupTypeId: string,
  currentData: CarePlan
) => {
  const result = await axios
    .post<Response, Response>("/careplan/add", {
      userId,
      pillarId,
      patientGroupTypeId,
      content: currentData,
    })
    .catch((err) => err);
  return result.data;
};

export const updateCarePlan = async (
  userId: string,
  pillarId: string,
  patientGroupTypeId: string,
  careplanId: string,
  content: CarePlan,
  attachments: []
) => {
  const result = await axios
    .put<Response, Response>("/careplan/update", {
      userId,
      pillarId,
      careplanId,
      patientGroupTypeId,
      content,
      attachments,
    })
    .catch((err) => err);
  return result.data;
};

export const removeCarePlan = async (userId: string, careplanId: string) => {
  try {
    const result = await axios.delete<Response>("/careplan/pillar", {
      data: { userId, careplanId },
    });
    return result.data;
  } catch (err) {
    return err;
  }
};

export const addCarePlanAttachment = async (
  userId: string,
  name: string,
  file: string,
  onProgress: (progress: number) => void
) => {
  try {
    const result: AxiosResponse = await axios.post(
      "/careplan/upload-attachment",
      {
        userId,
        name,
        file, // file is expected to be a string (e.g., Base64-encoded file)
      },
      {
        onUploadProgress: (progressEvent: any) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent?.total
          );
          onProgress(progress); // Call the progress handler with the current progress
        },
      }
    );
    return result.data;
  } catch (err) {
    console.error("Upload error:", err);
    return err;
  }
};

export const updatePublishUnpublish = async (
  careplanId: string,
  userId: string,
  actionType: string,
  isNotify: number
) => {
  const result = await axios
    .put("/careplan/action", {
      careplanId,
      userId,
      actionType,
      isNotify,
    })
    .catch((err) => err);

  return result.data;
};

export const fetchPillarTemplates = async (
  userId: string,
  pillarId: string,
  pageSize: number,
  pageNumber: number
) => {
  const result = await axios
    .get<FetchPillarTemplatesResponse, FetchPillarTemplatesResponse>(
      "/careplan/pillars/templates",
      {
        params: { userId, pillarId, pageSize, pageNumber },
      }
    )
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result;
};

export const uploadPillarAttachments = async (formData: FormData) => {
  return await axios.post(`/careplan/attachments`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export default {
  fetch,
  fetchPublished,
  post,
  sendToEpic,
  fetchProgressData,
  fetchPatientPillars,
  fetchPillarsPatientGroups,
  fetchCarePlanListById,
  addCarePlan,
  updateCarePlan,
  removeCarePlan,
  addCarePlanAttachment,
  updatePublishUnpublish,
  fetchPillarTemplates,
  uploadPillarAttachments,
};
