import clsx from "clsx";
import React, { ChangeEvent, useCallback, useEffect } from "react";
import { useRef, useState, useMemo } from "react";
import { MagnifyingGlass, XCircle } from "phosphor-react";
import { PatientOption, Option } from "../../lib/interfaces/input";
import styles from "./style.module.css";
import { SearchSelectLabel } from "../searchselectlabel/SearchSelectLabel";
import { PatientSearchDropdown } from "../patientSearchDropdown/PatientSearchDropdown";
import { MembershipTierDropdown } from "../MembershipTierDropdown/MembershipTierDropdown";
import debounce from "lodash/debounce";
import { MembershipTier, MembershipTierLabel } from "../../lib/interfaces/user";
import { useHistory } from "react-router-dom";
import { Tag } from "../tag/Tag";
import { Avatar } from "../avatar/Avatar";
import { ParamProps } from "../../lib/apis/patient";

interface PatientSearchSelectProps {
  headingText?: string;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  options: PatientOption[] | ((searchQuery: string) => PatientOption[]);
  value?: PatientOption | PatientOption[];
  onSelect?: (
    selectedOption: PatientOption | null | undefined,
    selected?: boolean
  ) => void;
  onChange?: (inputText: string) => void;
  onSelectMembership?: (inputText: string) => void;
  className?: string;
  loading?: boolean;
  dropdownStyle?: string;
  totalPatientCount?: number | undefined;
  searchText: string;
  selectMembershipTier: Option;
  loadMoreOptions?: () => void;
  showDropdown?: boolean;
  placeholder?: string;
  width?: number;
  setParams?: (value: ParamProps) => void;
  isPatientSearchPage: boolean;
}

const FilterOption: PatientOption[] = [
  { name: MembershipTierLabel.TIER_ALL, value: "" },
  { name: MembershipTierLabel.TIER_ONE, value: MembershipTier.TIER_ONE },
  { name: MembershipTierLabel.TIER_TWO, value: MembershipTier.TIER_TWO },
];

export const PatientSearchSelect: React.FC<PatientSearchSelectProps> = ({
  headingText,
  label,
  error = false,
  disabled = false,
  options = [],
  onSelect = () => {
    /* empty */
  },
  onChange = () => {
    /* empty */
  },
  onSelectMembership = () => {
    /* empty */
  },
  className,
  loading,
  dropdownStyle,
  totalPatientCount,
  searchText,
  selectMembershipTier,
  loadMoreOptions,
  showDropdown,
  placeholder,
  value,
  width,
  setParams,
  isPatientSearchPage,
}: PatientSearchSelectProps) => {
  const multiple = Array.isArray(value);
  const [searchValue, setSearchValue] = useState<string>(searchText);
  const [inputFocused, setInputFocused] = useState(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [membershipTierSelectValue, setMembershipTierSelectValue] = useState<
    MembershipTier | undefined
  >(undefined);
  const history = useHistory();

  const displayOptions = useMemo(() => {
    if (Array.isArray(options)) {
      return options;
    }
    if (typeof options === "function") {
      return options(searchValue);
    }
    return [];
  }, [options, searchValue]);

  const displaySelectedOption = useMemo(() => {
    if (multiple) return value.map((selectedOption) => selectedOption.value);
    else return value?.id;
  }, [value]);

  useEffect(() => {
    if (!multiple && value?.firstName) {
      const name = `${value.firstName} ${value.lastName}`;
      setSearchValue(name);
      setInputFocused(false);
    } else if (!multiple && value?.name) {
      setSearchValue(value?.name);
      setInputFocused(false);
    }
  }, [value]);

  const handleDebounceFn = (value: string) => {
    onChange(value);
  };

  const debounceFn = useCallback(debounce(handleDebounceFn, 700), []);

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    debounceFn(event.target.value);
  };

  const handleMemberFilter = (selectedMembershipTier: MembershipTier) => {
    setMembershipTierSelectValue(selectedMembershipTier);
    onSelectMembership(selectedMembershipTier);
    setSearchValue("");
  };

  const handleSearchIconPress = () => {
    onSelect({
      name: searchValue,
      value: "",
      membershipTier: membershipTierSelectValue,
    });
  };

  const handleOptionSelect = (patient: PatientOption, selected: boolean) => {
    if (!isPatientSearchPage) {
      onSelect(patient, selected);
      if (multiple) {
        inputRef.current?.blur();
      }
      return;
    }
    history.push(`patient/${patient?.value}/careplan`);
  };

  const clearInput = () => {
    onSelect({ name: "", value: "" });
    if (isSearch && typeof setParams === "function") {
      setParams({ pageNumber: 1, pageSize: 50, searchText: "" });
      setIsSearch(false);
    }

    setSearchValue("");
    inputRef.current?.blur();
  };

  return (
    <div
      className={clsx(styles.searchSelect, className)}
      style={{ flexGrow: 0, width: width ? width : "520px" }}
    >
      {headingText && headingText !== "" && (
        <SearchSelectLabel
          headingText={headingText}
          headingAlignment="center"
          size="28px"
        />
      )}
      {label && !isPatientSearchPage && (
        <label className={clsx(styles.label)}> {label} </label>
      )}
      <div className={clsx(styles.inputDropdownWrap)}>
        <div
          className={clsx(
            styles.inputContainer,
            inputFocused && styles.focused,
            error && styles.error,
            {
              [styles.patientSearchPadding]: isPatientSearchPage,
              [styles.inputContainerPadding]: !isPatientSearchPage,
            }
          )}
        >
          {isPatientSearchPage && (
            <MembershipTierDropdown
              membershipTierOptions={FilterOption}
              onChange={handleMemberFilter}
              selectMembershipTier={selectMembershipTier}
            />
          )}

          <div
            data-dd-privacy="mask"
            className={styles.inputTextContainer}
            onClick={() => inputRef.current?.focus()}
          >
            {!multiple && value ? (
              typeof value.avatarUrl === "string" && (
                <Avatar
                  size="xs"
                  photo={value.avatarUrl}
                  firstName={value.firstName}
                  lastName={value.lastName}
                />
              )
            ) : !isPatientSearchPage ? (
              <div className={clsx(styles.icon, styles.iconLeft)}>
                <MagnifyingGlass size={24} />
              </div>
            ) : null}

            {multiple &&
              value.length > 0 &&
              value.map((selectedOption) => (
                <div
                  key={selectedOption.id || selectedOption.value}
                  className={styles.tagContainer}
                >
                  <Tag
                    content={selectedOption.name}
                    key={selectedOption.value}
                    type="black"
                    onDelete={() => handleOptionSelect(selectedOption, false)}
                  />
                </div>
              ))}
            <input
              ref={inputRef}
              disabled={disabled}
              className={clsx(styles.input, {
                [styles.pl10]: !isPatientSearchPage,
              })}
              value={searchValue}
              placeholder={placeholder}
              onChange={(e) => {
                handleSearchInput(e);
                setInputFocused(true);
                setIsSearch(true);
              }}
              onFocus={() => setInputFocused(true)}
              onBlur={() => {
                setInputFocused(false);
              }}
            />
          </div>
          {totalPatientCount !== undefined &&
            totalPatientCount !== 0 &&
            isPatientSearchPage && (
              <div className={styles.resultCount}>
                {totalPatientCount}{" "}
                {totalPatientCount === 1 ? "Result" : "Results"}
              </div>
            )}

          {isPatientSearchPage && (
            <div onClick={handleSearchIconPress}>
              <MagnifyingGlass
                className={clsx(styles.icon, {
                  [styles.activeIcon]: inputFocused,
                })}
              />
            </div>
          )}

          {!multiple &&
            searchValue.length > 0 &&
            !disabled &&
            !isPatientSearchPage && (
              <button onClick={clearInput} className={styles.clearButton}>
                <XCircle weight="fill" size={20} />
              </button>
            )}
        </div>
        {inputFocused &&
          (searchValue.length > 0 || !isPatientSearchPage) &&
          showDropdown && (
            <PatientSearchDropdown
              onLoadMore={loadMoreOptions}
              dropdownStyle={dropdownStyle}
              data-dd-privacy="mask"
              options={displayOptions}
              selectedValue={displaySelectedOption}
              onOptionSelect={handleOptionSelect}
              loading={loading}
              isPatientSearchPage={isPatientSearchPage}
            />
          )}
      </div>
    </div>
  );
};
