import React from "react";

import {
  useContentShareControls,
  ScreenShare,
} from "amazon-chime-sdk-component-library-react";
import { BaseSdkProps } from "amazon-chime-sdk-component-library-react/lib/components/sdk/Base";

import { PERMISSION_DENIED_BY_SYSTEM } from "../../../../../constants/Errors";
import { logError } from "../../../../../lib/util/logger";
import { BaseControl } from "../BaseControl";

interface Props extends BaseSdkProps {
  label?: string;
  pauseLabel?: string;
  unpauseLabel?: string;
  iconTitle?: string;
}

export const ContentShareControl: React.FC<React.PropsWithChildren<Props>> = ({
  label = "Share",
  pauseLabel = "Pause",
  unpauseLabel = "Unpause",
  iconTitle,
  ...rest
}) => {
  const { toggleContentShare } = useContentShareControls();

  const toggleShare = async (): Promise<void> => {
    try {
      await toggleContentShare();
    } catch (error: unknown) {
      logError("Error toggling content share: ", {}, error as Error);
      if (error instanceof DOMException) {
        if (error.message === PERMISSION_DENIED_BY_SYSTEM) {
          alert(
            "Please enable screen recording in your system settings to allow content sharing"
          );
        }
      }
    }
  };

  return (
    <>
      <BaseControl
        icon={<ScreenShare title={iconTitle} color="#000000" width="2rem" />}
        onClick={toggleShare}
        label={label}
        options={undefined}
      />
    </>
  );
};

export default ContentShareControl;
