import { AxiosResponse } from "axios";
import { Attendee, JoinMeetingInfo } from "../../../containers/videoChat/types";

export interface JoinMeetingRequest {
  visitId: string;
  userId: string;
}
export interface JoinMeetingResponse extends AxiosResponse {
  data: JoinMeetingInfo;
}

export interface GetAttendeeRequest {
  externalMeetingId: string;
  externalUserId: string;
}

export interface GetAttendeeResponse extends AxiosResponse {
  data: Attendee & { Name: string };
}
export interface GetAttendeeNameResponse {
  name: string;
}

export interface EndMeetingRequest {
  externalMeetingId: string;
}

export enum SendLinkType {
  SMS = "sms",
  EMAIL = "email",
}

export interface SendWebLinkRequest {
  userId: string;
  visitId: string;
  type: SendLinkType;
  recipient: string;
}

export interface GetWebLinkRequest {
  userId: string;
  visitId: string;
}

export interface GetWebLinkResponse {
  videoChatLink: string;
}
