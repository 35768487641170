import clsx from "clsx";
import { MouseEvent, useRef, useState } from "react";
import { CaretDown, IconProps, XCircle } from "phosphor-react";
import { Body } from "../typography/body/Body";
import { Dropdown } from "../dropdown/Dropdown";
import { Option } from "../../lib/interfaces/input";
import styles from "./style.module.css";
import { Avatar } from "../avatar/Avatar";

interface SelectProps {
  label?: string;
  placeholder?: string;
  hint?: string;
  LeftIcon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  disabled?: boolean;
  error?: boolean;
  width?: number | string;
  options?: Option[];
  optional?: boolean;
  value: Option;
  displayedValue?: string;
  onChange?: (option: any) => void;
  className?: string;
  loading?: boolean;
  direction?: string;
  border?: boolean;
}

export const Select = ({
  label,
  placeholder,
  hint,
  LeftIcon,
  error = false,
  disabled,
  width,
  optional,
  options = [],
  value,
  displayedValue,
  onChange = () => {
    /* empty */
  },
  className,
  loading,
  direction,
  border,
}: SelectProps) => {
  const [focused, setFocused] = useState(false);
  const selectRef = useRef<any>();
  const handleOptionSelect = (option: Option, selected: boolean) => {
    onChange(option);
    selectRef.current.blur();
    setFocused(false);
  };

  const clearOption = (e: MouseEvent) => {
    e.stopPropagation();
    onChange({ name: "", value: "" });
  };

  return (
    <div
      className={clsx(styles.select, className)}
      style={width ? { flexGrow: 0, width: width } : undefined}
    >
      {label && <label className={clsx(styles.label)}> {label} </label>}

      <div
        onClick={() => (!disabled ? selectRef.current.focus() : null)}
        className={clsx(
          styles.valueContainer,
          focused && styles.focused,
          error && styles.error,
          disabled && styles.disabled,
          direction && styles.directionRight,
          border && styles.border
        )}
      >
        <div className={styles.avatarAndValue}>
          <div
            className={
              (typeof value.avatarUrl === "string" || LeftIcon) && styles.avatar
            }
          >
            {value.value && typeof value.avatarUrl === "string" ? (
              <Avatar
                data-dd-privacy="mask"
                size="xs"
                photo={value.avatarUrl}
                firstName={value.name.split(" ")[0]}
                lastName={value.name.split(" ")[1]}
              />
            ) : (
              LeftIcon && (
                <div className={clsx(styles.icon, styles.iconLeft)}>
                  <LeftIcon size={24} />
                </div>
              )
            )}
          </div>
          <div className={styles.selectedValue}>
            <input
              readOnly
              ref={selectRef}
              className={clsx(styles.value, direction && styles.directionRight)}
              placeholder={placeholder}
              value={displayedValue || value.name}
              disabled={disabled}
              onFocus={() => (!disabled ? setFocused(true) : null)}
              onBlur={() => setFocused(false)}
            />
            {value?.secondaryText && (
              <Body size="xs" color="secondary">
                {value.secondaryText}
              </Body>
            )}
          </div>
        </div>
        {optional && value.value !== "" && (
          <button onClick={clearOption} className={clsx(styles.clearButton)}>
            <XCircle weight="fill" size={20} />
          </button>
        )}

        <div className={clsx(styles.icon, styles.iconRight)}>
          <CaretDown size={20} />
        </div>
      </div>

      {focused && options && (
        <Dropdown
          options={options}
          selectedValue={value.value}
          onOptionSelect={handleOptionSelect}
          loading={loading}
          direction={direction}
        />
      )}

      {hint && (
        <Body
          className={styles.hint}
          size="sm"
          weight="regular"
          color={error ? "danger" : "secondary"}
        >
          {hint}
        </Body>
      )}
    </div>
  );
};
