import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { EditableArea } from "./Styled";

interface CkEditorProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export const Editor = ({
  value,
  onChange,
  disabled = false,
}: CkEditorProps) => {
  return (
    <EditableArea>
      <CKEditor
        disabled={disabled}
        editor={DecoupledEditor}
        data={value && value}
        onReady={(editor) => {
          const toolbarElement = editor && editor.ui.view.toolbar.element;
          const editableElement = editor.ui.getEditableElement();
          if (editor && toolbarElement && editableElement) {
            editableElement?.parentElement?.insertBefore(
              toolbarElement,
              editableElement
            );
          }
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
        config={{
          toolbar: [
            "bold",
            "italic",
            "underline",
            "numberedList",
            "bulletedList",
          ],
          licenseKey: "GPL",
        }}
      />
    </EditableArea>
  );
};
