import { useState, useEffect } from "react";
import styles from "./style.module.css";
import { Button } from "../../../components/button/Button";
import { CaretLeft, Plus } from "phosphor-react";
import { PatientDetailProps } from "../../../lib/interfaces/carePlan";
import { careplanPatientDetailFormatDateTime } from "../../../lib/util/date";
import { BaseContext, AlertContext } from "../../../lib/context/context";
import { useContext } from "react";
import clsx from "clsx";
import { Modal } from "../../../components/modal/Modal";
import { Option } from "../../../lib/interfaces/input";
import { AddCareForm } from "../../../components/carePlan/AddCarePlanForm";
import { useCarePlan } from "../../../lib/hooks/useCarePlan";
import { useParams } from "react-router-dom";
import { logError } from "../../../lib/util/logger";
import { marked } from "marked";

type PatientPillarDetailProps = {
  patient: PatientDetailProps;
  title: string;
};

const initialPillarValue: Option = {
  name: "Select one",
  value: "",
};

interface PatientRouteParam {
  userId: string;
}

export const PillarCarePlanHeader = ({
  patient,
  title,
}: PatientPillarDetailProps) => {
  const controller = new AbortController();
  const { userId } = useParams<PatientRouteParam>();

  useEffect(() => {
    return () => controller.abort();
  }, []);

  const {
    setIsPillarPageShow,
    setIsPillarEditShow,
    selectedPillarData,
    setShowAddNewCarePlanModal,
    groupTypeOptions,
    showAddNewCarePlanModal,
    setRefetchPillarTemplate,
  } = useContext(BaseContext);
  const { getCarePlanListById, addCarePlan } = useCarePlan();
  const { pushAlert } = useContext(AlertContext);
  const { name, assignedSex, dateOfBirth, identifiers, mrnId } = patient;

  const [selectedPillarValue, setSelectedPillarValue] =
    useState<Option>(initialPillarValue);

  const handleAddNewClick = () => {
    setShowAddNewCarePlanModal(true);
  };

  const handleSelection = (value: Option) => {
    setSelectedPillarValue(value);
  };

  const fetchCarePlanList = async (
    pillarId: string,
    patientGroupTypeId: string
  ) => {
    try {
      return await getCarePlanListById(
        pillarId,
        patientGroupTypeId,
        controller
      );
    } catch (error) {
      logError(
        "Error to fetch care plan list:",
        { pillarId, patientGroupTypeId },
        error as Error
      );
    }
  };

  const resetFormState = () => {
    setShowAddNewCarePlanModal(false);
    setSelectedPillarValue(initialPillarValue);
  };

  const handleNoCarePlanResult = () => {
    pushAlert("Content is not received from Contentful.", "danger");
    resetFormState();
  };

  const handleAddCarePlan = async (result: any[]) => {
    const [currentData] = result;

    if (!currentData) {
      return;
    }

    const convertMarkToHTML = currentData?.carePlanContent[0]?.body
      ? await marked(currentData?.carePlanContent[0]?.body)
      : "";

    const currentDataContent = {
      ...currentData,
      carePlanContent: [
        {
          ...currentData?.carePlanContent[0],
          body: convertMarkToHTML,
        },
      ],
    };

    try {
      const res = await addCarePlan(
        userId,
        selectedPillarData?.pillarId || "",
        selectedPillarValue.value,
        currentDataContent
      );
      if (res) {
        resetFormState();
        setRefetchPillarTemplate(true);
      }
    } catch (error) {
      logError("Error to add care plan:", { userId }, error as Error);
    }
  };

  const handleSubmit = async () => {
    const pillarId = selectedPillarData?.pillarId;
    const patientGroupTypeId = selectedPillarValue.value;

    if (pillarId && selectedPillarValue?.value) {
      try {
        const result = await fetchCarePlanList(pillarId, patientGroupTypeId);

        if (!result?.length) {
          handleNoCarePlanResult();
        } else {
          await handleAddCarePlan(result);
        }
      } catch (error) {
        logError(
          "Error to add care plan:",
          { pillarId, patientGroupTypeId },
          error as Error
        );
      }
    }
  };

  return (
    <header className={styles.content}>
      <Modal
        visible={showAddNewCarePlanModal}
        onCloseModal={() => {
          /* empty */
        }}
        title="Care Plan Template"
        className={styles.modalFormat}
        dismissable={false}
        icon={{
          title: selectedPillarData?.pillarName || "",
          iconColor: selectedPillarData?.icons?.smallColorIcon || "",
        }}
        verticalPosition="center"
      >
        <AddCareForm
          value={selectedPillarValue}
          options={groupTypeOptions || []}
          onChange={handleSelection}
          onSubmit={handleSubmit}
          onCancel={() => {
            setShowAddNewCarePlanModal(false);
            setSelectedPillarValue(initialPillarValue);
          }}
          isLoading={false}
        />
      </Modal>
      <Button
        size="small"
        type="secondary-gray"
        label="Back"
        Icon={CaretLeft}
        iconPosition="left"
        onClick={() => {
          setIsPillarPageShow(false);
          setIsPillarEditShow(false);
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "32px 0px 0px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={selectedPillarData?.icons?.smallColorIcon}
            alt="icon"
            width={40}
            height={40}
          />
          <span
            style={{
              marginLeft: "12px",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            {selectedPillarData?.pillarName}
          </span>
          <div style={{ marginLeft: "auto" }}>
            <Button
              onClick={() => handleAddNewClick()}
              label="Add New"
              Icon={Plus}
              iconPosition="left"
              className={clsx(styles.addNewButton)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 16,
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "150%",
            color: "#292524",
          }}
        >
          <span>{name}</span>
          <div
            style={{
              background: "#292524",
              borderRadius: "50%",
              padding: "2.5px",
              margin: "0 6px",
            }}
          ></div>
          <span>
            {assignedSex.replace(/^\w/, (match) => match.toUpperCase())}
          </span>
          <div
            style={{
              background: "#292524",
              borderRadius: "50%",
              padding: "2.5px",
              margin: "0 6px",
            }}
          ></div>
          <span>{careplanPatientDetailFormatDateTime(dateOfBirth)}</span>

          {mrnId && (
            <>
              <div
                style={{
                  background: "#292524",
                  borderRadius: "50%",
                  padding: "2.5px",
                  margin: "0 6px",
                }}
              ></div>
              <span>{mrnId}</span>
            </>
          )}
        </div>
      </div>
    </header>
  );
};
