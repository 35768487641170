import { Plus, Trash } from "phosphor-react";
import { v4 as uuidv4 } from "uuid";
import { Button } from "../../../components/button/Button";
import { Checkbox } from "../../../components/checkbox/Checkbox";
import { Body } from "../../../components/typography/body/Body";
import { Option } from "../../../lib/interfaces/input";
import styles from "./style.module.css";
import {
  Checked,
  WeeklyHoursArray,
  KeysArray,
  defaultKeys,
} from "../../../lib/interfaces/availbilityManagement";
import { weekdayAbbreviations } from "../weeklyHours/WeeklyHours";
import { Heading } from "../../../components/typography/heading/Heading";
import { SelectWeeklyHours } from "../selectWeeklyHours/SelectWeeklyHours";
import _ from "lodash";
import { useEffect, useState } from "react";

export const hourOptions: Option[] = [
  { name: "9:00am", value: "9:00am" },
  { name: "9:30am", value: "9:30am" },
  { name: "10:00am", value: "10:00am" },
  { name: "10:30am", value: "10:30am" },
  { name: "11:00am", value: "11:00am" },
  { name: "11:30am", value: "11:30am" },
  { name: "12:00pm", value: "12:00pm" },
  { name: "12:30pm", value: "12:30pm" },
  { name: "1:00pm", value: "1:00pm" },
  { name: "1:30pm", value: "1:30pm" },
  { name: "2:00pm", value: "2:00pm" },
  { name: "2:30pm", value: "2:30pm" },
  { name: "3:00pm", value: "3:00pm" },
  { name: "3:30pm", value: "3:30pm" },
  { name: "4:00pm", value: "4:00pm" },
  { name: "4:30pm", value: "4:30pm" },
  { name: "5:00pm", value: "5:00pm" },
];

export interface EditWeeklyHoursProps {
  weeklyHours: WeeklyHoursArray;
  checked: Checked;
  onCancel?: () => void;
  onSave?: (updWeeklyHours: WeeklyHoursArray, updChecked: Checked) => void;
}

export const EditWeeklyHours = ({
  weeklyHours,
  checked,
  onCancel = () => {
    /* empty */
  },
  onSave = () => {
    /* empty */
  },
}: EditWeeklyHoursProps) => {
  const [updWeeklyHours, setUpdWeeklyHours] =
    useState<WeeklyHoursArray>(weeklyHours);
  const [keys, setKeys] = useState<KeysArray>();
  const [updChecked, setUpdChecked] = useState<Checked>(checked);
  const [disableSave, setDisableSave] = useState<string[]>([]);

  useEffect(() => {
    if (weeklyHours) {
      const updKeys: KeysArray = JSON.parse(JSON.stringify(defaultKeys));
      weekdayAbbreviations.map((day) => {
        const updDayKeys = weeklyHours[day].map(() => {
          return uuidv4();
        });
        updKeys[day] = updDayKeys;
      });
      setKeys(updKeys);
    }
  }, [weeklyHours]);

  const saveDisabled = (dayIndex: string) => {
    setDisableSave([...disableSave, dayIndex]);
  };

  const saveNotDisabled = (dayIndex: string) => {
    const updDisableSave = JSON.parse(JSON.stringify(disableSave));
    _.remove(updDisableSave, (dayIndexString) => dayIndexString === dayIndex);
    setDisableSave(updDisableSave);
  };

  const editWeeklyHours = (
    hours: Option,
    day: string,
    index: number,
    type: string
  ) => {
    const newWeeklyHours = JSON.parse(JSON.stringify(updWeeklyHours)); //create a deep copy
    if (type === "to") {
      newWeeklyHours[day][index]["to"] = hours;
    } else newWeeklyHours[day][index]["from"] = hours;
    setUpdWeeklyHours(newWeeklyHours);
  };

  const toggleChecked = (day: string) => {
    const updatedChecked = JSON.parse(JSON.stringify(updChecked));
    updatedChecked[day] = !updatedChecked[day];
    setUpdChecked(updatedChecked);
  };

  const handleOnAdd = (day: string) => {
    const defaultHours = {
      from: { name: "9:00am", value: "9:00am" },
      to: { name: "5:00pm", value: "5:00pm" },
    };
    const hours = JSON.parse(JSON.stringify(updWeeklyHours));
    hours[day].push(defaultHours);
    setUpdWeeklyHours(hours);
    const updKeys = JSON.parse(JSON.stringify(keys));
    updKeys[day].push(uuidv4());
    setKeys(updKeys);
  };

  const handleOnDelete = (day: string, index: number) => {
    const allHours = JSON.parse(JSON.stringify(updWeeklyHours));
    allHours[day].splice(index, 1);
    setUpdWeeklyHours(allHours);
    const allKeys = JSON.parse(JSON.stringify(keys));
    allKeys[day].splice(index, 1);
    setKeys(allKeys);
    saveNotDisabled(day + index);
  };

  return (
    <>
      <Heading className={styles.weeklyHoursHeader} type="02">
        {"Set your weekly hours"}
      </Heading>
      {weekdayAbbreviations.map((day) => (
        <div key={day}>
          <div className={styles.editWeekdayAndHours}>
            <Checkbox
              onToggle={() => {
                toggleChecked(day);
              }}
              label={day}
              checked={updChecked[day]}
              className={styles.checkbox}
            />
            {updChecked[day] ? (
              <SelectWeeklyHours
                onChange={editWeeklyHours}
                day={day}
                index={0}
                startHour={weeklyHours[day][0].from}
                endHour={weeklyHours[day][0].to}
                saveDisabled={saveDisabled}
                saveNotDisabled={saveNotDisabled}
              />
            ) : (
              <Body size="md" color="secondary" className={styles.unavailable}>
                {"Unavailable"}
              </Body>
            )}
            <div className={styles.button}>
              <Button
                onClick={() => {
                  handleOnAdd(day);
                }}
                disabled={!updChecked[day]}
                type="tertiary"
                Icon={Plus}
                className={styles.disabled}
              />
            </div>
          </div>
          {keys &&
            updChecked[day] &&
            updWeeklyHours[day].length > 1 &&
            updWeeklyHours[day].slice(1).map((hours, index) => (
              <div
                key={keys[day][index + 1]}
                className={styles.editWeekdayAndHours}
              >
                <div className={styles.additionalHrs}>
                  <SelectWeeklyHours
                    key={keys[day][index + 1]}
                    onChange={editWeeklyHours}
                    day={day}
                    index={index + 1}
                    startHour={hours.from}
                    endHour={hours.to}
                    saveDisabled={saveDisabled}
                    saveNotDisabled={saveNotDisabled}
                  />
                </div>
                <div className={styles.button}>
                  <Button
                    onClick={() => {
                      handleOnDelete(day, index + 1);
                    }}
                    disabled={!checked}
                    type="tertiary"
                    Icon={Trash}
                    className={styles.disabled}
                  />
                </div>
              </div>
            ))}
        </div>
      ))}
      <div className={styles.cancelAndSaveButtons}>
        <Button
          onClick={() => {
            onCancel();
          }}
          type="secondary-gray"
          label={"Cancel"}
          className={styles.cancelButton}
        />
        <Button
          onClick={() => {
            onSave(updWeeklyHours, updChecked);
          }}
          type="primary"
          label={"Save Schedule"}
          disabled={disableSave.length > 0}
          className={styles.saveButton}
        />
      </div>
    </>
  );
};
